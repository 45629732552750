<template>
  <li
    v-if="canViewVerticalNavMenuLink(item)"
    class="nav-item"
    :class="{
      'active': isActive,
      'disabled': item.disabled
    }"
  >
    <b-link
      v-bind="linkProps"
      class="d-flex align-items-center"
    >
      <feather-icon
        :icon="item.icon || 'CircleIcon'"
      />
      <span class="menu-title text-truncate">
        {{ $te(`routes.${item.name}`) ? t(`routes.${item.name}`) : item.name }}
      </span>
      <b-badge
        v-if="badge"
        pill
        variant="warning"
        class="mr-1 ml-auto"
      >
        {{ badge }}
      </b-badge>
    </b-link>
  </li>
</template>

<script>
import { useUtils as useAclUtils } from '@core/libs/acl'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import useVerticalNavMenuLink from './useVerticalNavMenuLink'
import mixinVerticalNavMenuLink from './mixinVerticalNavMenuLink'
import { mapGetters } from 'vuex'

export default {
  mixins: [mixinVerticalNavMenuLink],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { isActive, linkProps, updateIsActive } = useVerticalNavMenuLink(
      props.item
    )
    const { t } = useI18nUtils()
    const { canViewVerticalNavMenuLink } = useAclUtils()

    return {
      isActive,
      linkProps,
      updateIsActive,

      // ACL
      canViewVerticalNavMenuLink,

      // i18n
      t,
    }
  },
  computed: {
    ...mapGetters(['requests', 'bills']),
    badge() {
      if (this.item.name === 'agents_requests') {
        return this.requests?.length || null
      }
      if (this.item.name === 'billings' && this.bills) {
        const { totalItems } = this.bills
        return totalItems> 0 ? totalItems : null
      }
      return null
    },
  },
}
</script>
