<template>
  <div class="logout-menu">
    <b-link
      class="logout-menu"
      @click="logout"
    >
      <feather-icon
        icon="LogOutIcon" 
        class="text-danger"
      />
      <span
        class="menu-title text-truncate text-danger"
      >
        {{ t('buttons.logout') }}
      </span>
    </b-link>
  </div>
</template>

<script>
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { mapActions } from 'vuex'

export default {
  name: 'LogoutMenu',
  components: {
  },
  props: {
  },
  setup() {
    const { t } = useI18nUtils()

    return {
      t,
    }
  },
  methods: {
    ...mapActions(['logout']),
  },
}
</script>

<style lang="scss" scoped>
.logout-menu {
  position: absolute;
  bottom: 10px;
  left: 12px;
  width: 100%;
  font-size: 1.1rem;
}
</style>
